<template>
  <!-- MandagMorgen -->
  <NuxtLink
    v-if="isMm && paper.Url"
    :to="{ name: 'paper', params: { paper: paper.Url } }"
    class="block"
    :class="[isLarge ? 'max-w-full' : 'w-20']"
  >
    <div
      class="rounded-lg overflow-hidden h-60 md:h-90 p-5 bg-contain bg-no-repeat bg-bottom"
      :style="[
        { backgroundColor: paper.ColorRGB },
        {
          backgroundImage:
            'url(https://legacy.mm.dk/images/altinget8/covers/paper-cover-' +
            paper.RecordId +
            '.png)',
        },
      ]"
    >
      <div class="list-title-l">
        {{ paper.Name }}
      </div>
    </div>
  </NuxtLink>

  <!-- Altinget -->
  <div
    v-else-if="paper.Url"
    class="flex flex-col h-full"
    :class="[isLarge ? 'max-w-full' : 'w-24']"
  >
    <NuxtLink :to="{ name: 'paper', params: { paper: paper.Url } }">
      <div
        class="flex flex-col h-full"
        :class="[type === 'slider' ? 'body-m' : 'body-xs']"
      >
        <div
          class="bg-blue-light rounded-lg overflow-hidden"
          :class="isLarge ? 'max-w-full' : 'w-24 h-24'"
        >
          <template v-if="isLarge">
            <div class="mb-2 p-4 flex items-center">
              <img
                class="w-5 h-5 inline-block mr-2"
                :alt="'Altinget ' + paper.Name"
                src="/A_Logomark.svg"
              /><span class="inline-block list-title-xxxs text-white">{{
                paper.Name
              }}</span>
            </div>
            <NuxtPicture
              :src="`https://${config.public.site.legacyurl}/images/altinget8/covers/paper-cover-${paper.RecordId}.png`"
              :alt="'Altinget ' + paper.Name"
              width="520"
              height="520"
              sizes="sm:520px"
              format="webp"
              class="w-full h-auto max-w-full aspect-square -mt-6"
            />
          </template>

          <NuxtPicture
            v-else
            :src="`https://${config.public.site.legacyurl}/images/altinget8/covers/paper-cover-${paper.RecordId}-96.png`"
            :alt="'Altinget ' + paper.Name"
            format="webp"
            sizes="sm:96px"
            width="96"
            height="96"
            class="w-full h-auto max-w-full aspect-square"
          />
        </div>
        <div :class="isLarge ? 'list-title-s text-center mt-2' : 'mt-1'">
          {{ paper.Name }}
        </div>
        <div v-if="isLarge" class="mt-2 body-xs text-center">
          {{ paper.MetaDescription }}
        </div>
      </div>
    </NuxtLink>
    <div
      v-if="isLarge && type !== 'slider' && !hasSubscription"
      class="text-center mt-auto"
    >
      <NuxtLink :to="{ name: 'subscription' }">
        <CommonButton
          bg="bg-blue"
          text="text-white"
          border="border-none"
          class="mt-5"
          size="medium"
        >
          {{ $t('CreateProfile') }}
        </CommonButton>
      </NuxtLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ContentPaper } from '~/typesManual/content_api/paper'

const config = useRuntimeConfig()
const userStore = useUserStore()
const { isMm } = useBrand()

const props = withDefaults(
  defineProps<{
    paper: ContentPaper
    type?: string
  }>(),
  {
    type: 'slider',
  }
)

const hasSubscription = computed(() =>
  userStore.hasSubscription(props.paper.RecordId)
)

const isLarge = computed(
  () =>
    props.type === 'large' ||
    props.type === 'default' ||
    props.type === 'slider'
)
</script>
